import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/seo";
//Images
import { StaticImage } from "gatsby-plugin-image";

const Entrepreneurs = () => {
    return (
        <Layout>
            <Seo
                title="Contratistas - BIKESAFE"
                description="Todos los productos de Bike Safe están fabricados con materiales de calidad superior y mano de obra experta. Queremos ayudar a propagar el uso de la bicicleta, pero queremos que sea un uso de la bicicleta de calidad. Utilizamos soldadura continua y procesos de acabado de vanguardia. El galvanizado en caliente (de conformidad con la norma BS 1461:1999) o el revestimiento de poliéster pulverizado son los acabados estándar de todos los soportes para guardar bicicletas. Todos nuestros productos están fabricados aquí, en el Reino Unido, por especialistas en mecanizado y artesanos cualificados."
            />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center">
                        Contratistas
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" sm="12">
                        <h5 className="float-lg-left subheading-product uppercase">Bicicletas de calidad:</h5>
                    </Col>
                    <Col lg="6" sm="12">
                        <p className="flow-text">
                        Todos los productos de Bike Safe están fabricados con materiales de calidad superior y mano de obra experta. Queremos ayudar a propagar el uso de la bicicleta, pero queremos que sea un uso de la bicicleta de calidad. Utilizamos soldadura continua y procesos de acabado de vanguardia. El galvanizado en caliente (de conformidad con la norma BS 1461:1999) o el revestimiento de poliéster pulverizado son los acabados estándar de todos los soportes para guardar bicicletas. Todos nuestros productos están fabricados aquí, en el Reino Unido, por especialistas en mecanizado y artesanos cualificados.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="mt-5 mb-5 text-center">
                        <StaticImage placeholder="blurred"  src="../images/entrepreneurs.jpg" className="shadow fluid rounded" />
                        <em className="flow-text">"Sus plazos de entrega fueron cortos y tratar con ustedes no generó ningún problema"
                            Lee Irvine, Irvine Utilities, Londres</em>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <h3>Características de los productos de Bike Safe:</h3>
                        <ul className="mt-3">
                            <li className="border p-2 shade-on-hover">Sin mantenimiento: nuestros productos son muy resistentes y se se fabrican para durar</li>
                            <li className="border p-2 shade-on-hover">Fácil instalación en cualquier superficie</li>
                            <li className="border p-2 shade-on-hover">Estética: los soportes para guardar bicicletas atractivos situados delante de los edificios mejoran la arquitectura y se utilizan con mayor frecuencia</li>
                            <li className="border p-2 shade-on-hover">Estacionamiento de bicicletas eficiente y ordenado</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default Entrepreneurs;